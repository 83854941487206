var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mt-5 w-85 big-div",staticStyle:{"position":"relative","z-index":"100"},style:(_vm.getPageDesign() +
      `zoom:${this.pageZoomValue ? this.pageZoomValue : 90}%;`)},[(_vm.pageDesign.pageStyles.imageAlign == 'no')?_c('div',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),staticClass:"bg",style:(_vm.getbackgroundImage(
          _vm.IsImageAdded,
          _vm.pageDesign.imageBlock,
          _vm.pageDesign.pageStyles.imageAlign,
          _vm.isDesktopView
        ) + _vm.getBgDivStyles())}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage),expression:"showLeftImage"}],staticClass:"col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0",class:{
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division': _vm.isDesktopView,
      },style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : '',on:{"click":_vm.activeImageCustomize}},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
              `pic-${_vm.getSelectedPage.order}`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)]),_c('button',{staticClass:"hide close-icon",attrs:{"id":"clear"},on:{"click":_vm.deleteImage}},[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z","fill":"black","fill-opacity":"0.5"}}),_c('path',{attrs:{"d":"M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z","fill":"white"}})])])]),_c('div',{class:!_vm.IsImageAdded ? 'col-12' : _vm.imageAddedLayout,style:(_vm.getMainPaddingCss(_vm.pageDesign.pageStyles, _vm.isDesktopView) +
        _vm.getScrollCSS(
          _vm.pageDesign.pageStyles,
          _vm.isDesktopView,
          _vm.pageDesign.imageBlock.style,
          _vm.pageDesign.pageType
        ) +
        _vm.calculateContentWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive,
          _vm.pageDesign.pageStyles
        ))},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"flex-end","height":"100%"}},[_c('div',{staticClass:"h-100",staticStyle:{"overflow":"hidden scroll"}},[_c('div',[_c('ProgressBar',{attrs:{"order":_vm.CurrentPage.order}})],1),_c('draggable',_vm._b({staticClass:"dragArea list-group",staticStyle:{"cursor":"move"},attrs:{"group":"actions","chosen-class":"chosen","drag-class":"drag"},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false}},model:{value:(_vm.pageDesignBlocks),callback:function ($$v) {_vm.pageDesignBlocks=$$v},expression:"pageDesignBlocks"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.pageDesign.blocksArray),function(pro,ind){return _c('div',{key:`${pro.type}-${ind}`,class:_vm.dragging && pro.type != 'button' ? 'dragElementBorder' : null,attrs:{"draggable":"false"},on:{"drop":function($event){return _vm.handleElementDrop($event, ind)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();},"click":function($event){return _vm.changeCustomize(ind)},"mouseover":function($event){return _vm.addHoverPorperty(ind)},"mouseleave":function($event){return _vm.removeHoverPorperty(ind)}}},[_c('div',{staticClass:"content",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style) + _vm.getDivBorderCss(pro)),attrs:{"id":`${pro.type}-${ind}`}},[(pro.customizeStatus)?_c('EditorAction',{attrs:{"deleteCondition":_vm.getDeleteButtonCondition(pro),"currElementIndex":ind,"currElementType":pro.type}}):_vm._e(),(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
                    _vm.getPaddingCss(pro.style) +
                    _vm.getMarginCss(pro.style, _vm.isDesktopView) +
                    _vm.getColorWithOpacity(pro.style) +
                    _vm.getTextRotate(pro.style) +
                    _vm.getLineHeight(pro.style) +
                    _vm.getTextAlign(pro.style) +
                    _vm.getFontStyle(pro.style, _vm.isDesktopView)),domProps:{"innerHTML":_vm._s(_vm.getEditorPersonalisedValueStyle(pro.content))}}):_vm._e(),(pro.type == 'html')?_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                    _vm.getColorWithOpacity(pro.style) +
                    _vm.getTextRotate(pro.style) +
                    _vm.getTextAlign(pro.style))},[_c('HtmlElement',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e(),(pro.type == 'option')?_c('div',[_c('div',[(_vm.checkboxInputEditMode)?_c('span',{staticClass:"m-2 d-inline-block",staticStyle:{"cursor":"pointer"}},[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z","fill":"black","fill-opacity":"0.5"}}),_c('path',{attrs:{"d":"M10.2858 23.2593C10.2858 24.4 11.1429 25.3333 12.1905 25.3333H19.8096C20.8572 25.3333 21.7143 24.4 21.7143 23.2593V10.8148H10.2858V23.2593ZM12.1905 12.8889H19.8096V23.2593H12.1905V12.8889ZM19.3334 7.70369L18.381 6.66666H13.6191L12.6667 7.70369H9.33337V9.77777H22.6667V7.70369H19.3334Z","fill":"white"}})])]):_vm._e(),_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                        _vm.getTextAlign(pro.style))},[(_vm.getCurrentSelectedOptionType === 'text')?_c('div',[(pro.style.textAlign != 'end')?_c('div',{class:pro.textOptionAlignment == 'vertical'
                              ? 'd-flex flex-column'
                              : 'd-flex  flex-wrap'},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('div',{key:index,class:[
                              pro.textOptionAlignment == 'horizontal'
                                ? 'mr-2'
                                : '',
                              _vm.QuestionPageType == 'multipleAnswer'
                                ? 'form-check checkboxDiv'
                                : 'radioboxDiv d-flex align-items-start',
                            ],style:(_vm.QuestionPageType == 'singleAnswer'
                                ? _vm.selectedCheckboxColor(pro.style)
                                : _vm.selectedCheckboxColor(pro.style))},[_c('div',{staticClass:"d-flex align-items-center w-100",style:(`justify-content:${pro.style.textAlign};`)},[((_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'checkbox'
                                    : 'radio')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOptions),expression:"selectedOptions"}],class:_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'form-check-input m-0 mr-2'
                                    : 'mr-2 mt-1',style:({
                                  height: pro.style.fontSize + 'px',
                                  width: pro.style.fontSize + 'px',
                                  position: 'relative',
                                }),attrs:{"id":index,"type":"checkbox"},domProps:{"value":_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox.value}`
                                    : `${checkbox.value}`,"checked":Array.isArray(_vm.selectedOptions)?_vm._i(_vm.selectedOptions,_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox.value}`
                                    : `${checkbox.value}`)>-1:(_vm.selectedOptions)},on:{"change":function($event){var $$a=_vm.selectedOptions,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox.value}`
                                    : `${checkbox.value}`,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedOptions=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedOptions=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedOptions=$$c}}}}):((_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'checkbox'
                                    : 'radio')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOptions),expression:"selectedOptions"}],class:_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'form-check-input m-0 mr-2'
                                    : 'mr-2 mt-1',style:({
                                  height: pro.style.fontSize + 'px',
                                  width: pro.style.fontSize + 'px',
                                  position: 'relative',
                                }),attrs:{"id":index,"type":"radio"},domProps:{"value":_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox.value}`
                                    : `${checkbox.value}`,"checked":_vm._q(_vm.selectedOptions,_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox.value}`
                                    : `${checkbox.value}`)},on:{"change":function($event){_vm.selectedOptions=_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox.value}`
                                    : `${checkbox.value}`}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOptions),expression:"selectedOptions"}],class:_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'form-check-input m-0 mr-2'
                                    : 'mr-2 mt-1',style:({
                                  height: pro.style.fontSize + 'px',
                                  width: pro.style.fontSize + 'px',
                                  position: 'relative',
                                }),attrs:{"id":index,"type":_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'checkbox'
                                    : 'radio'},domProps:{"value":_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox.value}`
                                    : `${checkbox.value}`,"value":(_vm.selectedOptions)},on:{"input":function($event){if($event.target.composing)return;_vm.selectedOptions=$event.target.value}}}),_c('label',{class:_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'form-check-label'
                                    : '',style:(_vm.getColorCss(pro.style) +
                                  _vm.getColorWithOpacity(pro.style) +
                                  _vm.getFontStyle(pro.style)),attrs:{"for":index}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.value)}})])])])}),0):_c('div',{class:pro.textOptionAlignment == 'vertical'
                              ? 'd-flex flex-column'
                              : 'd-flex  flex-wrap'},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('div',{key:index,staticClass:"d-flex align-items-start",class:[
                              pro.textOptionAlignment == 'horizontal'
                                ? 'mr-2'
                                : '',
                              _vm.QuestionPageType == 'multipleAnswer'
                                ? 'form-check checkboxDiv'
                                : 'radioboxDiv',
                            ],style:(_vm.QuestionPageType == 'singleAnswer'
                                ? _vm.selectedCheckboxColor(pro.style)
                                : _vm.selectedCheckboxColor(pro.style))},[_c('div',{staticClass:"d-flex align-items-center w-100",style:(`justify-content:${pro.style.textAlign};`)},[_c('label',{staticClass:"m-0",class:_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'form-check-label '
                                    : 'mr-2',style:(_vm.getColorCss(pro.style) +
                                  _vm.getColorWithOpacity(pro.style) +
                                  _vm.getFontStyle(pro.style)),attrs:{"for":`${_vm.CurrentPage.order}-${index}-right-checkbox`}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.value)}})]),((_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'checkbox'
                                    : 'radio')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(pro.selectedOption),expression:"pro.selectedOption"}],class:_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'form-check-input m-0 ml-2 '
                                    : 'mt-1',style:({
                                  height: pro.style.fontSize + 'px',
                                  width: pro.style.fontSize + 'px',
                                  position: 'relative',
                                }),attrs:{"id":`${_vm.CurrentPage.order}-${index}-right-checkbox`,"type":"checkbox"},domProps:{"value":_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox}`
                                    : `${checkbox.value}`,"checked":Array.isArray(pro.selectedOption)?_vm._i(pro.selectedOption,_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox}`
                                    : `${checkbox.value}`)>-1:(pro.selectedOption)},on:{"change":function($event){var $$a=pro.selectedOption,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox}`
                                    : `${checkbox.value}`,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(pro, "selectedOption", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(pro, "selectedOption", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(pro, "selectedOption", $$c)}}}}):((_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'checkbox'
                                    : 'radio')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(pro.selectedOption),expression:"pro.selectedOption"}],class:_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'form-check-input m-0 ml-2 '
                                    : 'mt-1',style:({
                                  height: pro.style.fontSize + 'px',
                                  width: pro.style.fontSize + 'px',
                                  position: 'relative',
                                }),attrs:{"id":`${_vm.CurrentPage.order}-${index}-right-checkbox`,"type":"radio"},domProps:{"value":_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox}`
                                    : `${checkbox.value}`,"checked":_vm._q(pro.selectedOption,_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox}`
                                    : `${checkbox.value}`)},on:{"change":function($event){return _vm.$set(pro, "selectedOption", _vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox}`
                                    : `${checkbox.value}`)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(pro.selectedOption),expression:"pro.selectedOption"}],class:_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'form-check-input m-0 ml-2 '
                                    : 'mt-1',style:({
                                  height: pro.style.fontSize + 'px',
                                  width: pro.style.fontSize + 'px',
                                  position: 'relative',
                                }),attrs:{"id":`${_vm.CurrentPage.order}-${index}-right-checkbox`,"type":_vm.QuestionPageType == 'multipleAnswer'
                                    ? 'checkbox'
                                    : 'radio'},domProps:{"value":_vm.QuestionPageType == 'multipleAnswer'
                                    ? `${checkbox}`
                                    : `${checkbox.value}`,"value":(pro.selectedOption)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(pro, "selectedOption", $event.target.value)}}})])])}),0)]):_vm._e(),(_vm.getCurrentSelectedOptionType === 'image')?_c('div',[_c('div',{staticClass:"row"},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('div',{key:index,staticClass:"d-flex flex-column",class:_vm.isDesktopView
                                ? `col-${12 / pro.OptionsPerRow}`
                                : `col-${12 / pro.OptionsPerMobile}`,style:(_vm.getDivAlignmentStyle(pro.style.textAlign) +
                              _vm.getSpaceBetweenRow(pro.style))},[_c('div',{staticClass:"ImageInputBlock position-relative",style:(_vm.getImageHeightWidth(
                                  pro.style,
                                  _vm.isDesktopView
                                ) +
                               `${
                                  _vm.IsSelectedImageExist(checkbox)
                                    ? `border:2px solid ${
                                        pro.style.selectedImageIconColor ||
                                        '#000000'
                                      };padding:2px;`
                                    : null
                                }`),on:{"click":function($event){return _vm.checkSelectedImage(checkbox)}}},[_c('LazyImage',{key:_vm.ImageSrc(checkbox.image) ||
                                  '/images/image_placeholder.png' + index,staticStyle:{"cursor":"pointer","height":"100%","width":"100%"},style:(_vm.getBorderRadius(pro.style)),attrs:{"src":_vm.ImageSrc(checkbox.image) ||
                                  `/images/image_placeholder.png`,"alt":""}})],1),_c('div',[_c('p',{staticClass:"m-0 p-0 mt-2",style:(_vm.getColorCss(pro.style) +
                                  _vm.getColorWithOpacity(pro.style) +
                                  _vm.getFontStyle(pro.style)),domProps:{"innerHTML":_vm._s(checkbox.value)}})])])}),0)]):_vm._e(),(_vm.getCurrentSelectedOptionType === 'tab')?_c('div',[(pro.textOptionAlignment == 'vertical')?_c('div',[_c('ul',{staticClass:"m-0 p-0",style:(`max-width: ${
                              _vm.isDesktopView
                                ? pro.style.defaultTab.maxTabWidthDesktop ||
                                  500
                                : pro.style.defaultTab.maxTabWidthMobile ||
                                  500
                            }px;
                              list-style: none;
                              display: inline-block;`)},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('li',{key:index,staticClass:"mb-2",class:_vm.isDesktopView ? 'Tab' : '',style:(`min-width:${
                                _vm.isDesktopView
                                  ? pro.style.defaultTab.minTabWidthDesktop ||
                                    200
                                  : pro.style.defaultTab.minTabWidthMobile ||
                                    200
                              }px; max-width: ${
                                _vm.isDesktopView
                                  ? pro.style.defaultTab.maxTabWidthDesktop ||
                                    500
                                  : pro.style.defaultTab.maxTabWidthMobile ||
                                    500
                              }px;`)},[_c('div',[(_vm.QuestionPageType == 'singleAnswer')?_c('button',{staticClass:"btn tab-btn border w-100",style:(_vm.SelectedValue == checkbox.value
                                      ? _vm.getSelectedTabStyles(
                                          pro.style.selectedTab,
                                          pro.style
                                        )
                                      : _vm.getDefaultTabStyles(
                                          pro.style.defaultTab,
                                          pro.style
                                        )),on:{"click":function($event){return _vm.updateSelectedAnswerRadio(checkbox.value)}}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.value)}})]):_c('button',{staticClass:"btn tab-btn border w-100",style:(_vm.isSelectedOptionExist(checkbox)
                                      ? _vm.getSelectedTabStyles(
                                          pro.style.selectedTab,
                                          pro.style
                                        )
                                      : _vm.getDefaultTabStyles(
                                          pro.style.defaultTab,
                                          pro.style
                                        )),on:{"click":function($event){return _vm.updateSelectedAnswer(checkbox)}}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.value)}})])])])}),0)]):_c('div',{},[_c('div',{staticClass:"m-0 p-0 row",staticStyle:{"display":"flex","flex-wrap":"wrap"},style:(`height:100%;max-width: 100%; justify-content:${pro.style.textAlign};`)},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('div',{key:index,staticClass:"mb-2",class:[
                                _vm.isDesktopView ? 'Tab' : '',
                                _vm.isDesktopView
                                  ? pro.optionSetting.horizontalOptionsPerRow
                                    ? `col-${
                                        12 /
                                        pro.optionSetting
                                          .horizontalOptionsPerRow
                                      }`
                                    : 'col-6 col-sm-6 col-md-4'
                                  : pro.optionSetting
                                      .horizontalOptionsPerRowMobile
                                  ? `col-${
                                      12 /
                                      pro.optionSetting
                                        .horizontalOptionsPerRowMobile
                                    }`
                                  : 'col-6 col-sm-6 col-md-4',
                              ],staticStyle:{"flex-grow":"1"}},[_c('div',{staticStyle:{"height":"100% !important"}},[(_vm.QuestionPageType == 'singleAnswer')?_c('button',{staticClass:"btn tab-btn border w-100",staticStyle:{"height":"100% !important"},style:(_vm.SelectedValue == checkbox.value
                                      ? _vm.getSelectedTabStyles(
                                          pro.style.selectedTab,
                                          pro.style
                                        )
                                      : _vm.getDefaultTabStyles(
                                          pro.style.defaultTab,
                                          pro.style
                                        )),on:{"click":function($event){return _vm.updateSelectedAnswerRadio(checkbox.value)}}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.value)}})]):_c('button',{staticClass:"btn tab-btn border w-100",staticStyle:{"height":"100% !important"},style:(_vm.isSelectedOptionExist(checkbox)
                                      ? _vm.getSelectedTabStyles(
                                          pro.style.selectedTab,
                                          pro.style
                                        )
                                      : _vm.getDefaultTabStyles(
                                          pro.style.defaultTab,
                                          pro.style
                                        )),on:{"click":function($event){return _vm.updateSelectedAnswer(checkbox)}}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.value)}})])])])}),0)])]):_vm._e(),(_vm.getCurrentSelectedOptionType === 'dropdown')?_c('div',[_c('div',{staticClass:"select-div"},[_c('multiselect',{staticClass:"multiSelect",style:(_vm.getColorCss(pro.style) +
                              _vm.getColorWithOpacity(pro.style) +
                              _vm.getFontStyle(pro.style) +
                              _vm.QuestionPageStyle(pro.style)),attrs:{"options":_vm.getOptionsArr,"multiple":true,"close-on-select":false,"searchable":false,"label":"value","track-by":"value"},model:{value:(_vm.selectedOptionsMulti),callback:function ($$v) {_vm.selectedOptionsMulti=$$v},expression:"selectedOptionsMulti"}})],1)]):_vm._e()])])]):_vm._e(),(pro.type == 'imageComp')?_c('div',[_c('InlineImageComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e(),(pro.type == 'tooltip')?_c('div',{style:('display:flex;justify-content:' + pro.style.position + ';')},[_c('button',{style:(_vm.getButtonCss(pro.style, _vm.isDesktopView) +
                      _vm.getCircularBtn(pro.isBtnRounded)),on:{"mouseenter":function($event){pro.isTooltipVisible = true},"mouseleave":function($event){pro.isTooltipVisible = false}}},[(pro.useIcon)?_c('span',[_c('BIcon',{attrs:{"icon":pro.selectedIcon}})],1):_c('span',{style:({
                        opacity: `${pro.style.textOpacity}%`,
                        fontSize: `${pro.style.fontSize}px`,
                      })},[_vm._v(" "+_vm._s(pro.text)+" ")])]),(pro.isTooltipVisible)?_c('ToolTipComp',{attrs:{"tooltipData":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e()],1):_vm._e()],1)])}),0)],1),_c('div',[_c('div',{on:{"click":function($event){return _vm.changeStaticBtnCustomisation(_vm.getButtonIndex)},"mouseover":function($event){return _vm.addStaticBtnHoverPorperty()},"mouseleave":function($event){return _vm.removeStaticBtnHoverPorperty()}}},[_c('div',{style:(_vm.getHeightWidth(_vm.StaticButtonElement.style) +
                _vm.getStaticButtonDivCss(_vm.staticButtonStyles))},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex",style:(_vm.getBackNextButtonPosition(
                      _vm.StaticButtonElement,
                      _vm.CurrentPage.value
                    ))},[(
                      !_vm.StaticButtonElement.content.backButton.hideBackButton
                    )?_c('button',{style:(_vm.getCommonButtonCSS(
                        _vm.StaticButtonElement.style,
                        _vm.isDesktopView
                      ) +
                      _vm.getBackNextButtonCss(
                        _vm.StaticButtonElement.content.backButton.style
                      ))},[_c('span',{style:({
                        opacity: `${_vm.StaticButtonElement.content.backButton.style.textOpacity}%`,
                        fontSize: `${_vm.StaticButtonElement.content.backButton.style.fontSize}px`,
                      })},[_vm._v(_vm._s(_vm.StaticButtonElement.content.backButton.text))])]):_vm._e(),_c('button',{style:(_vm.getCommonButtonCSS(
                        _vm.StaticButtonElement.style,
                        _vm.isDesktopView
                      ) +
                      _vm.getBackNextButtonCss(
                        _vm.StaticButtonElement.content.nextButton.style
                      ))},[_c('span',{style:({
                        opacity: `${_vm.StaticButtonElement.content.nextButton.style.textOpacity}%`,
                        fontSize: `${_vm.StaticButtonElement.content.nextButton.style.fontSize}px`,
                      })},[_vm._v(_vm._s(_vm.StaticButtonElement.content.nextButton.text))])])])])])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage),expression:"showRightImage"}],staticClass:"col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0",class:{
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division': _vm.isDesktopView,
      },style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : '',on:{"click":_vm.activeImageCustomize}},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
              `pic-${_vm.getSelectedPage.order}`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)]),_c('button',{staticClass:"hide close-icon",attrs:{"id":"clear"},on:{"click":_vm.deleteImage}},[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z","fill":"black","fill-opacity":"0.5"}}),_c('path',{attrs:{"d":"M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z","fill":"white"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }